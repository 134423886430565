

function build_path(...args) {
  return args.map((part, i) => {
    if (i === 0) {
      return part.trim().replace(/[\/]*$/g, '')
    } else {
      return part.trim().replace(/(^[\/]*|[\/]*$)/g, '')
    }
  }).filter(x => x.length).join('/')
}

export async function getWS(url, options) {
  let result = null;
  let savedInnerHtml;

  if (options && options.hasOwnProperty('buttonId')) {
    savedInnerHtml = document.getElementById(options.buttonId).innerHTML;
    document.getElementById(options.buttonId).innerHTML = document.getElementById(options.buttonId).innerHTML +
      '<span class="loading loading-ring loading-sm"></span>';
  }

  try {
    //url = build_path(window.location.href, url);

    // Clerk requires the full URL and I can't modify a request on the server. So,
    // Make sure thee base URL prefixes the path.
    url = window.location.protocol + "//" + window.location.host + url;
    
    // after this line, our function will wait for the `fetch()` call to be settled
    // the `fetch()` call will either return a Response or throw an error
    const response = await fetch(
      url,
      {
        headers: { "Content-Type": "application/json" },
        credentials: 'include'
      }
    );
    if (!response.ok) {
      throw new Error(`HTTP error: ${response.status}`);
    }
    // after this line, our function will wait for the `response.json()` call to be settled
    // the `response.json()` call will either return the parsed JSON object or throw an error
    result = await response.json();
  } catch (error) {
    console.error(`${url} ${error}`);
  }

  if (savedInnerHtml) {
    document.getElementById(options.buttonId).innerHTML = savedInnerHtml;
  }

  return result;
}

export async function postWS(url, data) {
  var result = null;

  try {
    //url = build_path(window.location.href, url);

    // Clerk requires the full URL and I can't modify a request on the server. So,
    // Make sure thee base URL prefixes the path.
    url = window.location.protocol + "//" + window.location.host + url;
    
    var response = await fetch(
      url,
      {
        headers: { "Content-Type": "application/json" },
        method: "POST",
        body: JSON.stringify(data),
        credentials: 'include'
      }
    )
    result = await response.json();
  } catch (error) {
    console.error(`${url} ${error}`);
  }

  return result;
}
